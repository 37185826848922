import React from "react"
import { Link } from "gatsby"
import './Landing.css'
import lomito1 from '../../assets/Assets_Lomito1Crop.png'
import lomito2 from '../../assets/Assets_Lomito2Crop.png'
import lomito3 from '../../assets/Assets_Lomito3Crop.png'


const Landing = props => {
    
    return (
        <main className='landing'>
            <div className="topImages">
                <img src={lomito1} id="lomito1" alt="" />
                <img src={lomito2} id="lomito2" alt="" />
                <img src={lomito3} id="lomito3" alt="" />
            </div>
            <header className='landingHeader'>
                <h1 id="landingTitle">Lomito city</h1>
            </header>
            <section className='landingBody'>
                <Link to='/app/play'><button className='landingButton btn btn-lg btn-outline-secondary'>Jugar</button></Link>
            </section>
            <section className='landingDescription'>
                <div id="descriptionContainer"><h2 id="descriptionText">¡Pasea a tu perrito virtual y participa en una
<Link to="/app/research" className="research-link"> investigación de lenguaje</Link>!</h2></div>
            </section>
            <div className="bottomImages">
            </div>
            <div className="ayuda"> <a href="https://www.youtube.com/watch?v=o17nsKwac9w" target="window">Ver tutorial</a></div>
        </main>
    )
}

export default Landing