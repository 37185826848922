import React from "react"
import Landing from "../components/Landing/Landing"
import Layout from '../components/layout'
import SEO from '../components/seo'


const IndexPage = () => {
  return (
    <Layout>
      <SEO title='Inicio' keywords={[`lomitocity`, `lomitos`, `paseo`]} />
      <Landing />
    </Layout>
  )
}

export default IndexPage;
